<template>
    <div class="">
        <h1 class="bottom-0">{{ header }}</h1>
        <div class="max500">
            <ChirpSettings
                v-if="!loading"
                :data_source="settings_rows"
                :level="'company'"
                :level_id="level_id"
                ref="chirp_settings"
                class="flex bottom space-between wrap colx2 pad-10-20 comp-ins"
            />
        </div>

        <hr class="top-10" />

        <div class="add-payer bottom-10 max500">
            <div class="font-16 weight-600 dark-text bottom-0">Add Insurance Payer</div>
            <div class="pad-10-20">
                <label class="margin-0">Select Insurance Payers You Accept from the List</label>
                <Multiselect
                    v-model="the_chosen_payer"
                    :options="payerOptions"
                    track-by="id"
                    :options-limit="1000"
                    label="payer_label"
                />
                <div class="align-right">
                    <button class="top-20 primary" @click="add_ins_payer_to">Add Payer</button>
                </div>
            </div>
        </div>

        <div class="pick-payer bottom-20 max500">
            <div class="font-16 weight-600 dark-text">Insurance Payers That You Have Added</div>
            <div class="pad-10-20">
                <div class="font-12">Select Insurance Payer to View and Edit Details</div>
                <Multiselect
                    v-model="insurance_payer"
                    :options="insurance_payers_list"
                    track-by="id"
                    :options-limit="1000"
                    label="payer_label"
                />
            </div>
        </div>

        <div class="font-16 weight-600 dark-text">Details</div>
        <div class="max500">
            <div class="single-payer-details max500 pad-10-20">
                <div class="fullwidth flex colx2 space-between bottom-10">
                    <Input
                        class="flex-2 right-15"
                        type="text"
                        placeholder="Payer Name"
                        v-model="insurance_payer.payer_name"
                        label="Payer Name"
                    />
                    <Input
                        class="flex-1"
                        type="text"
                        placeholder="Payer Id"
                        v-model="insurance_payer.payer_id"
                        label="Payer Id"
                        :disabled="true"
                    />
                </div>
                <Input
                    class="bottom-10 block"
                    type="text"
                    placeholder="Street Address 1"
                    v-model="insurance_payer.payer_street_one"
                    label="Street Address 1"
                />
                <Input
                    class="bottom-10 block"
                    type="text"
                    placeholder="Street Address 2"
                    v-model="insurance_payer.payer_street_two"
                    label="Street Address 2"
                />
                <div class="fullwidth flex colx3 space-between bottom-20">
                    <Input
                        class="flex-4 right-15"
                        type="text"
                        placeholder="City"
                        v-model="insurance_payer.payer_city"
                        label="City"
                    />
                    <UsStates class="flex-1 right-15" v-model="insurance_payer.payer_state" label="State" />
                    <Input
                        class="flex-1"
                        type="text"
                        placeholder="Zip"
                        v-model="insurance_payer.payer_zip"
                        label="Zip"
                    />
                </div>
                <div class="align-right">
                    <button class="primary bottom-30" @click.prevent="update_payer()">Update Details</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const api_root = 'settings/list/company';

    import ChirpSettings from '@/components/general/list/ChirpSettings';

    export default {
        name: 'CompanyInsurance',
        components: { ChirpSettings },
        data() {
            return {
                loading: 1,
                settings_rows: [],
                level_id: 0,
                payerOptions: [],
                the_chosen_payer: {},
                insurance_payer: {},
                insurance_payers_list: [],
                insurance_payers_row_ndx: 0
            };
        },
        computed: {
            header() {
                return this.headerText || this.pageMeta.title;
            }
        },
        methods: {
            async update_payers(js) {
                let new_val = JSON.stringify(js);
                this.settings_rows[this.insurance_payers_row_ndx].value = new_val;
                this.$set(this.settings_rows, this.insurance_payers_row_ndx, this.settings_rows[this.insurance_payers_row_ndx]);
                this.$refs.chirp_settings.update_setting(
                    new_val,
                    this.settings_rows[this.insurance_payers_row_ndx],
                    this.insurance_payers_row_ndx
                );
                await this.get_insurance_payers();
            },
            async update_payer() {
                let js = JSON.parse(this.settings_rows[this.insurance_payers_row_ndx].value);
                let ndx = js.findIndex((x) => x.id == this.insurance_payer.id);
                if (ndx != -1) {
                    js[ndx] = this.insurance_payer;
                    await this.update_payers(js);
                }
            },
            async get_insurance_payers() {
                const my_payers = this.settings_rows[this.insurance_payers_row_ndx].value;
                if (my_payers.length) {
                    this.insurance_payers_list = JSON.parse(my_payers);
                    this.insurance_payers_list = this.insurance_payers_list.map(function(e) {
                        e.payer_label = e.payer_id + ' : ' + e.payer_name;
                        return e;
                    });
                    if (this.insurance_payers_list[0]) {
                        if(!this.insurance_payer || Object.keys(this.insurance_payer).length == 0) {
                            this.insurance_payer = this.insurance_payers_list[0];
                        }
                    }
                }
            },
            async add_ins_payer_to() {
                if (this.the_chosen_payer) {
                    let js = JSON.parse(this.settings_rows[this.insurance_payers_row_ndx].value);
                    let ndx = js.findIndex((x) => x.id === this.the_chosen_payer.id);
                    if (ndx == -1) {
                        js.push(this.the_chosen_payer);
                        await this.update_payers(js);
                    }
                }
            },
            async getPayerOptions() {
                const body = {
                    criteria: {
                        page: { num_per_page: '10000', page_num: 1 }
                    }
                };
                const res = await this.$api.post(`/insurance_payers`, body);

                this.payerOptions = res.data.rows.map(function(e) {
                    e.payer_label = e.payer_id + ' : ' + e.payer_name;
                    return e;
                });
            },
            async init() {
                this.level_id = this.$store.state.user.company_id;
                const res = await this.$api.get(`/${api_root}/${this.level_id}/insurance`);
                this.settings_rows = res.data;
                this.insurance_payers_row_ndx = this.settings_rows.findIndex((x) => x.setting === 'insurance_payers');
            }
        },
        async created() {
            await this.getPayerOptions();
            await this.init();
            this.loading = 0;
            await this.get_insurance_payers();
        }
    };
</script>
